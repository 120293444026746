body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #18161B;
  color: white;
  text-decoration: none;
}

#root,
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-header,
  &-footer {
    background-color: #18161B;
    text-align: center;
    color: white;
    width: 100%;
    height: 32px;
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0 auto;
    margin-top: auto;
    max-width: 80%;
    flex-wrap: wrap;
    &-link,
    &-text {
      color: white;
      text-decoration: none;
      margin: 0 4px;
      font-size: 80%;
    }
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    background-color: #18161B;

    &-img {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    &-h1 {
      margin: 0 auto;
      font-family: 'Philosopher', sans-serif;
      display: flex;
      align-items: baseline;
      text-decoration: none;
      color: white;

      &-text {
        font-size: x-large;
      }
    }

    &-classement {
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      max-height: 300px;
      overflow-y: auto;
      padding: 10px;
      width: 95%;
      font-size: 90%;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #18161B;
      }

      &::-webkit-scrollbar-thumb {
        background: #6d4901;
      }
    }
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #3e2900 #18161B;
}