.About {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    &-text {
        color: white;
        &-title {
            font-family: 'Philosopher', sans-serif;
        }
    }
    &-link {
        //text-decoration: none;
        color: white;
    }
}