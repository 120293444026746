.TermsAndPrivacy {
    display:flex;
    flex-direction: column;
    //height: 100vh;
    margin: 0 auto;
    background-color: #18161B;
    flex:1;
    //height: 100%;
    //flex: 1;
    //justify-content: space-between;
    &-terms {
        
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        //height: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        width:70%;
        text-align: center;
        // overflow-y: auto;
        padding-bottom: 60px;
        &-text {
            font-size: 80%;
            color: white;
            align-content: center;
            &-title {
                font-family: 'Philosopher', sans-serif;
            }
        }
        &-link {
            color: white;
        }
    }
    &-privacy {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        width: 70%;
        text-align: center;
        padding-bottom: 60px;
        &-text {
            font-size: 80%;
            color: white;
            align-content: center;
            
        }
        &-title {
            font-family: 'Philosopher', sans-serif;
        }
        &-link {
            color: white;
        }
    }
    &-link {
        color:white;
        margin-left: auto;
        margin-right: auto;
    }
}
